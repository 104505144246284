define("onboarding/validations/project", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    helpType: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    stage: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    executionStage: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    summary: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    nature: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })]
  };
  _exports.default = _default;
});