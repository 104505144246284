define("onboarding/routes/project/technical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectTechnicalRoute extends Ember.Route {
    afterModel(model, transition) {
      const skip = model.helpType === "business";

      if (skip) {
        if (transition.from.name === "project.nature") {
          this.replaceWith('project.business');
        } else if (transition.from.name === "project.business") {
          this.replaceWith('project.nature');
        }
      }
    }

  }

  _exports.default = ProjectTechnicalRoute;
});