define("onboarding/templates/project/budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bl43aqul",
    "block": "[[[1,[28,[35,0],[\"Budget\"],null]],[1,\"\\n\"],[10,\"form\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"question\"],[12],[1,\"\\n        What is your budgetary expectation regarding a given month during the execution phase?\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"text\"],[12],[1,\"\\n        This question informs the size of the team and the initial schedule guesstimates.\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@model\",\"@field\"],[[30,1],\"budget\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"Less than 5.000 GBP\",\"less\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"5.000 GBP - 10.000 GBP\",\"5-10\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"10.000 GBP - 25.000 GBP\",\"10-25\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"25.000 GBP - 50.000 GBP\",\"25-50\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"More than 50.000 GBP\",\"more\",[30,4],[30,2],[30,3]]],null],[1,\"\\n    \"]],[2,3,4]]]]],[1,\"\\n    \"],[8,[39,3],[[24,0,\"button button-navigation inline narrow\"]],[[\"@route\"],[\"project.company\"]],[[\"default\"],[[[[10,1],[12],[8,[39,4],null,[[\"@icon\"],[\"chevron-circle-left\"]],null],[13]],[]]]]],[1,\"\\n    \"],[8,[39,3],[[24,0,\"button button-navigation inline\"]],[[\"@route\"],[\"project.team\"]],[[\"default\"],[[[[10,1],[12],[8,[39,4],null,[[\"@icon\"],[\"chevron-circle-right\"]],null],[1,\" CONTINUE\"],[13]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\",\"field\",\"update\",\"selected\"],false,[\"page-title\",\"animated-radio-container\",\"animated-radio\",\"link-to\",\"fa-icon\"]]",
    "moduleName": "onboarding/templates/project/budget.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});