define("onboarding/routes/requested", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RequestedRoute extends Ember.Route {}

  _exports.default = RequestedRoute;
});