define("onboarding/controllers/meeting-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MeetingSetupController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['assigned_to', 'event_type_uuid', 'event_type_name', 'event_start_time', 'event_end_time', 'invitee_uuid', 'invitee_full_name', 'invitee_email', 'answer_1']);

      _defineProperty(this, "category", null);

      _defineProperty(this, "event_type_uuid", null);

      _defineProperty(this, "event_type_name", null);

      _defineProperty(this, "event_start_time", null);

      _defineProperty(this, "event_end_time", null);

      _defineProperty(this, "invitee_uuid", null);

      _defineProperty(this, "invitee_full_name", null);

      _defineProperty(this, "invitee_email", null);

      _defineProperty(this, "answer_1", null);
    }

    get eventStartTimeFormatted() {
      const date = new Date(Date.parse(this.event_start_time));
      return `${date.getUTCHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    get eventEndTimeFormatted() {
      const date = new Date(Date.parse(this.event_end_time));
      return `${date.getUTCHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    get eventStartDateFormatted() {
      const date = new Date(Date.parse(this.event_start_time));
      return date.toDateString();
    }

    get eventEndDateFormatted() {
      const date = new Date(Date.parse(this.event_end_time));
      return date.toDateString();
    }

  }

  _exports.default = MeetingSetupController;
});