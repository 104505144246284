define("onboarding/templates/type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2F/K+Xoc",
    "block": "[[[10,\"form\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"question\"],[12],[1,\"Are you representing a company or an indidual?\"],[13],[1,\"\\n    \"],[8,[39,0],[[24,0,\"button button-navigation\"]],[[\"@route\",\"@model\",\"@query\"],[\"contact-info\",\"Individual\",[28,[37,1],null,[[\"service\"],[[30,0,[\"service\"]]]]]]],[[\"default\"],[[[[10,1],[12],[1,\"INDIVIDUAL\"],[13]],[]]]]],[1,\"\\n    \"],[8,[39,0],[[24,0,\"button button-navigation\"]],[[\"@route\",\"@model\",\"@query\"],[\"contact-info\",\"Company\",[28,[37,1],null,[[\"service\"],[[30,0,[\"service\"]]]]]]],[[\"default\"],[[[[10,1],[12],[1,\"COMPANY\"],[13]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"link-to\",\"hash\"]]",
    "moduleName": "onboarding/templates/type.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});