define("onboarding/components/animated-checkbox-container", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="animated-checkbox-container">
      {{yield @field this.updateField (get @model @field)}}
  </div>
  */
  {
    "id": "TJCikBH1",
    "block": "[[[10,0],[14,0,\"animated-checkbox-container\"],[12],[1,\"\\n    \"],[18,3,[[30,1],[30,0,[\"updateField\"]],[28,[37,1],[[30,2],[30,1]],null]]],[1,\"\\n\"],[13]],[\"@field\",\"@model\",\"&default\"],false,[\"yield\",\"get\"]]",
    "moduleName": "onboarding/components/animated-checkbox-container.hbs",
    "isStrictMode": false
  });

  let AnimatedCheckboxContainerComponent = (_dec = Ember._action, (_class = class AnimatedCheckboxContainerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "values", []);
    }

    updateField(value, event) {
      const checked = event.currentTarget.checked;

      if (checked) {
        this.values.push(value);
      } else {
        this.values.splice(this.values.indexOf(value));
      }

      this.args.model[this.args.field] = this.values.toString();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "updateField", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateField"), _class.prototype)), _class));
  _exports.default = AnimatedCheckboxContainerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnimatedCheckboxContainerComponent);
});