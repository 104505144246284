define("onboarding/controllers/contact-info", ["exports", "onboarding/validations/nda-request", "fetch"], function (_exports, _ndaRequest, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactInfoController = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ContactInfoController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['service']);

      _initializerDefineProperty(this, "service", _descriptor, this);

      _defineProperty(this, "ndaRequestValidations", _ndaRequest.default);

      _initializerDefineProperty(this, "firebaseApp", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);
    }

    async saveContactInfo(auth, changeset) {
      if (changeset.isInvalid) {
        return;
      }

      changeset.phoneNumber = auth.currentUser.phoneNumber;
      changeset.createdAt = new Date().getTime();
      changeset.service = this.service;
      return changeset.save();
    }

    async skipNDA(changeset) {
      try {
        const auth = await this.firebaseApp.auth();

        if (auth.currentUser) {
          await this.saveContactInfo(auth, changeset);
          this.transitionToRoute('project.type');
        } else {
          alert("unauthenticated");
          this.transitionToRoute('index');
        }
      } catch (error) {
        alert(error);
      }
    }

    async generateNDA(changeset) {
      try {
        const auth = await this.firebaseApp.auth();

        if (auth.currentUser) {
          await this.saveContactInfo(auth, changeset);
          let companyHookUri = `https://us-central1-refineri-client-onboarding.cloudfunctions.net/companyNDA?phone=${encodeURIComponent(auth.currentUser.phoneNumber)}`;
          let IndividualHookUri = `https://us-central1-refineri-client-onboarding.cloudfunctions.net/individualNDA?phone=${encodeURIComponent(auth.currentUser.phoneNumber)}`;
          let hookUri = this.router.currentURL.includes("Individual") ? IndividualHookUri : companyHookUri;
          await (0, _fetch.default)(hookUri);
          auth.signOut();
          this.transitionToRoute('requested');
        } else {
          alert("unauthenticated");
          this.transitionToRoute('index');
        }
      } catch (error) {
        alert(error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "service", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firebaseApp", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveContactInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "saveContactInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "skipNDA", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "skipNDA"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateNDA", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "generateNDA"), _class.prototype)), _class));
  _exports.default = ContactInfoController;
});