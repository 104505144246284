define("onboarding/validations/nda-request", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }), (0, _validators.validateFormat)({
      type: "email"
    })],
    fullName: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }), (0, _validators.validateLength)({
      min: 3
    })]
  };
  _exports.default = _default;
});