define("onboarding/routes/project/index", ["exports", "onboarding/models/login"], function (_exports, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectIndexRoute extends Ember.Route {
    model() {
      return new _login.default();
    }

  }

  _exports.default = ProjectIndexRoute;
});