define("onboarding/templates/project/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "33nTLkwP",
    "block": "[[[1,[28,[35,0],[\"Company\"],null]],[1,\"\\n\"],[10,\"form\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"question\"],[12],[1,\"How many employees does your company have? \"],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@model\",\"@field\"],[[30,1],\"company\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"1 - 10\",\"less\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"11 - 50\",\"5-10\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"50 - 100\",\"10-25\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"100 - 500\",\"25-50\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"500+\",\"more\",[30,4],[30,2],[30,3]]],null],[1,\"\\n    \"]],[2,3,4]]]]],[1,\"\\n    \"],[8,[39,3],[[24,0,\"button button-navigation inline narrow\"]],[[\"@route\"],[\"project.business\"]],[[\"default\"],[[[[10,1],[12],[8,[39,4],null,[[\"@icon\"],[\"chevron-circle-left\"]],null],[13]],[]]]]],[1,\"\\n    \"],[8,[39,3],[[24,0,\"button button-navigation inline\"]],[[\"@route\"],[\"project.budget\"]],[[\"default\"],[[[[10,1],[12],[8,[39,4],null,[[\"@icon\"],[\"chevron-circle-right\"]],null],[1,\" CONTINUE\"],[13]],[]]]]],[1,\"\\n\"],[13]],[\"@model\",\"field\",\"update\",\"selected\"],false,[\"page-title\",\"animated-radio-container\",\"animated-radio\",\"link-to\",\"fa-icon\"]]",
    "moduleName": "onboarding/templates/project/company.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});