define("onboarding/routes/project/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectCompanyRoute extends Ember.Route {
    afterModel(model, transition) {
      const skip = !["notstartup", "company", "investment-seed", "investment-1", "investment-2", "investment-3"].includes(model.stage);

      if (skip) {
        if (!transition) {
          this.replaceWith('project.index');
        } else if (transition.from.name === "project.business") {
          this.replaceWith('project.budget');
        } else if (transition.from.name === "project.budget") {
          this.replaceWith('project.business');
        }
      }
    }

  }

  _exports.default = ProjectCompanyRoute;
});