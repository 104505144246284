define("onboarding/components/fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='firepit'>
    <div class='fire'>
      <div class='flame'></div>
      <div class='flame'></div>
      <div class='flame'></div>
      <div class='flame'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
      <div class='spark'></div>
    </div>
  </div>
  
  */
  {
    "id": "/aOgOEX3",
    "block": "[[[10,0],[14,0,\"firepit\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"fire\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flame\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"flame\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"flame\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"flame\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"spark\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "onboarding/components/fire.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});