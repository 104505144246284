define("onboarding/templates/project/nature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JO+TI5ex",
    "block": "[[[10,\"form\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"question\"],[12],[1,\"What's the nature of your project?\"],[13],[1,\"\\n    \"],[8,[39,0],null,[[\"@model\",\"@field\"],[[30,1],\"nature\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"Digital (Cloud & Applications)\",\"digital\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,1],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"Brick & Mortar (Physical presence & Manufacturing)\",\"physical\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,1],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"Both\",\"both\",[30,4],[30,2],[30,3]]],null],[1,\"\\n    \"]],[2,3,4]]]]],[1,\"\\n    \"],[8,[39,2],[[24,0,\"button button-navigation inline narrow\"]],[[\"@route\"],[\"project.execution-stage\"]],[[\"default\"],[[[[10,1],[12],[8,[39,3],null,[[\"@icon\"],[\"chevron-circle-left\"]],null],[13]],[]]]]],[1,\"\\n    \"],[8,[39,2],[[24,0,\"button button-navigation inline\"]],[[\"@route\"],[\"project.technical\"]],[[\"default\"],[[[[10,1],[12],[8,[39,3],null,[[\"@icon\"],[\"chevron-circle-right\"]],null],[1,\" CONTINUE\"],[13]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\",\"field\",\"update\",\"selected\"],false,[\"animated-radio-container\",\"animated-radio\",\"link-to\",\"fa-icon\"]]",
    "moduleName": "onboarding/templates/project/nature.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});