define("onboarding/templates/contact-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nndlZXMN",
    "block": "[[[43,[28,[37,1],[[30,1],[30,0,[\"ndaRequestValidations\"]]],null],[[[1,\"    \"],[11,\"form\"],[24,\"autocomplete\",\"off\"],[4,[38,2],[\"submit\",[28,[37,3],[[30,0,[\"generateNDA\"]],[30,2]],null]],null],[12],[1,\"\\n        \"],[8,[39,4],[[24,\"}}\",\"\"]],[[\"@icon\",\"@label\",\"@changeset\",\"@field\"],[\"envelope-square\",\"EMAIL\",[30,2],\"email\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],[[24,1,\"email\"],[24,\"size\",\"100\"]],[[\"@type\",\"@value\"],[\"text\",[30,2,[\"email\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[39,4],[[24,\"}}\",\"\"]],[[\"@icon\",\"@label\",\"@changeset\",\"@field\"],[\"portrait\",\"FULL NAME\",[30,2],\"fullName\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],[[24,1,\"email\"],[24,\"size\",\"100\"]],[[\"@type\",\"@value\"],[\"text\",[30,2,[\"fullName\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[11,\"button\"],[24,0,\"button button-action\"],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"generateNDA\"]],[30,2]],null]],null],[12],[1,\"\\n            \"],[10,1],[12],[8,[39,6],null,[[\"@icon\"],[\"bolt\"]],null],[1,\" REQUEST NDA\"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[11,\"button\"],[24,0,\"button button-navigation\"],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"skipNDA\"]],[30,2]],null]],null],[12],[1,\"\\n            \"],[10,1],[12],[8,[39,6],null,[[\"@icon\"],[\"chevron-right\"]],null],[1,\" SKIP NDA \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n    \"],[13],[1,\"\\n\"]],[2]],null]],[\"@model\",\"changeset\"],false,[\"with\",\"changeset\",\"on\",\"fn\",\"animated-input-wrapper\",\"input\",\"fa-icon\"]]",
    "moduleName": "onboarding/templates/contact-info.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});