define("onboarding/router", ["exports", "onboarding/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('type');
    this.route('contact-info', {
      path: '/contact-info/:nda_type'
    });
    this.route('requested');
    this.route('project', function () {
      this.route('stage');
      this.route('nature');
      this.route('summary');
      this.route('execution-stage');
      this.route('documents');
      this.route('type');
      this.route('company');
      this.route('business');
      this.route('technical');
      this.route('budget');
      this.route('team');
    });
    this.route('meeting');
    this.route('meeting-setup');
  });
});