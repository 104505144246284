define("onboarding/routes/project/business", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectBusinessRoute extends Ember.Route {
    afterModel(model, transition) {
      const skip = model.helpType === "development";

      if (skip) {
        if (transition.from.name === "project.technical") {
          this.replaceWith('project.company');
        } else if (transition.from.name === "project.company") {
          this.replaceWith('project.business');
        }
      }
    }

  }

  _exports.default = ProjectBusinessRoute;
});