define("onboarding/routes/project/type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectTypeRoute extends Ember.Route {
    model() {
      return this.modelFor("project");
    }

  }

  _exports.default = ProjectTypeRoute;
});