define("onboarding/controllers/project/index", ["exports", "firebase", "onboarding/validations/login"], function (_exports, _firebase, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProjectIndexController = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ProjectIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "loginValidations", _login.default);

      _initializerDefineProperty(this, "sent", _descriptor, this);

      _initializerDefineProperty(this, "firebaseApp", _descriptor2, this);
    }

    formSubmit(changeset, event) {
      event.preventDefault();

      if (!this.sent) {
        this.sendConfirmationCode(changeset);
      } else {
        this.confirmConfirmationCode(changeset);
      }
    }

    setupRecaptchaVerifier() {
      this.verifier = new _firebase.default.auth.RecaptchaVerifier('send-confirmation-code', {
        'size': 'invisible'
      });
    }

    async sendConfirmationCode(changeset) {
      if (changeset.isInvalid) {
        return;
      }

      await changeset.save();

      try {
        const auth = await this.firebaseApp.auth();
        this.confirmationResult = await auth.signInWithPhoneNumber(this.model.phoneNumber, this.verifier);
        this.sent = true;
      } catch (error) {
        alert(error);
      }
    }

    async confirmConfirmationCode(changeset) {
      if (changeset.isInvalid) {
        return;
      }

      await changeset.save();

      try {
        const result = await this.confirmationResult.confirm(this.model.confirmationCode);

        if (result.user) {
          const companies = this.store.query('Company', {
            filter: {
              phoneNumber: this.model.phoneNumber
            }
          });
          const individuals = this.store.query('Individual', {
            filter: {
              phoneNumber: this.model.phoneNumber
            }
          });
          const results = await Promise.all([companies, individuals]);

          if (results[0].length || results[1].length) {
            this.transitionToRoute('project.type');
          } else {
            alert("Please Request and NDA before continuuing the process");
            this.transitionToRoute('index');
          }
        }
      } catch (error) {
        alert(error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firebaseApp", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "formSubmit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupRecaptchaVerifier", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setupRecaptchaVerifier"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendConfirmationCode", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "sendConfirmationCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmConfirmationCode", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "confirmConfirmationCode"), _class.prototype)), _class));
  _exports.default = ProjectIndexController;
});