define("onboarding/components/animated-checkbox", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
      <Input {{on "change" (fn @onupdate @value)}} @checked={{this.checked}} @type="checkbox" name="{{@name}}" id={{concat @name "-" @value}} />
      <label for="{{concat @name "-" @value}}" >
          <span></span>
          {{@label}}
          <ins><i>{{@label}}</i></ins>
      </label>
  </div>
  */
  {
    "id": "Hyvhk/Wi",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[8,[39,0],[[16,3,[29,[[30,1]]]],[16,1,[28,[37,1],[[30,1],\"-\",[30,2]],null]],[4,[38,2],[\"change\",[28,[37,3],[[30,3],[30,2]],null]],null]],[[\"@checked\",\"@type\"],[[30,0,[\"checked\"]],\"checkbox\"]],null],[1,\"\\n    \"],[10,\"label\"],[15,\"for\",[29,[[28,[37,1],[[30,1],\"-\",[30,2]],null]]]],[12],[1,\"\\n        \"],[10,1],[12],[13],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n        \"],[10,\"ins\"],[12],[10,\"i\"],[12],[1,[30,4]],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@name\",\"@value\",\"@onupdate\",\"@label\"],false,[\"input\",\"concat\",\"on\",\"fn\"]]",
    "moduleName": "onboarding/components/animated-checkbox.hbs",
    "isStrictMode": false
  });

  class AnimatedCheckboxComponent extends _component.default {
    get checked() {
      return this.args.selected.split(",").includes(this.args.value);
    }

  }

  _exports.default = AnimatedCheckboxComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnimatedCheckboxComponent);
});