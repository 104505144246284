define("onboarding/templates/meeting-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ldehsP+1",
    "block": "[[[10,\"form\"],[14,\"autocomplete\",\"off\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[8,[39,0],[[24,0,\"page-logo\"]],[[\"@icon\"],[\"calendar-check\"]],null],[13],[1,\"\\n    \"],[10,0],[14,0,\"subheader\"],[12],[1,\"MEETING IS SET UP!\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"text\"],[12],[1,\"\\n        You have set up a meeting at \"],[10,1],[14,0,\"emphasis\"],[12],[1,[30,0,[\"eventStartDateFormatted\"]]],[13],[1,\" between \"],[10,1],[14,0,\"emphasis\"],[12],[1,[30,0,[\"eventStartTimeFormatted\"]]],[13],[1,\" and \"],[10,1],[14,0,\"emphasis\"],[12],[1,[30,0,[\"eventEndTimeFormatted\"]]],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n            see you soon! \\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,3],[14,6,\"http://refineri.co.uk/\"],[14,0,\"button button-navigation wide\"],[12],[1,\"\\n        GO BACK TO REFINERI.CO.UK\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"fa-icon\"]]",
    "moduleName": "onboarding/templates/meeting-setup.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});