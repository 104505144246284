define("onboarding/routes/application", ["exports", "emberfire/mixins/analytics-route"], function (_exports, _analyticsRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationRoute extends Ember.Route.extend(_analyticsRoute.default) {}

  _exports.default = ApplicationRoute;
});