define("onboarding/routes/project/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectTeamRoute extends Ember.Route {}

  _exports.default = ProjectTeamRoute;
});