define("onboarding/components/animated-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
      <input type="radio" name="{{@name}}" checked={{eq @selected @value}} id={{concat @name "-" @value}} />
      <label {{on "click" (fn @onupdate @value)}} for="{{concat @name "-" @value}}" >
          <span></span>
          {{@label}}
          <ins><i>{{@label}}</i></ins>
      </label>
  </div>
  */
  {
    "id": "g5KgzQY/",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[10,\"input\"],[15,3,[29,[[30,1]]]],[15,\"checked\",[28,[37,0],[[30,2],[30,3]],null]],[15,1,[28,[37,1],[[30,1],\"-\",[30,3]],null]],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[11,\"label\"],[16,\"for\",[29,[[28,[37,1],[[30,1],\"-\",[30,3]],null]]]],[4,[38,2],[\"click\",[28,[37,3],[[30,4],[30,3]],null]],null],[12],[1,\"\\n        \"],[10,1],[12],[13],[1,\"\\n        \"],[1,[30,5]],[1,\"\\n        \"],[10,\"ins\"],[12],[10,\"i\"],[12],[1,[30,5]],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@name\",\"@selected\",\"@value\",\"@onupdate\",\"@label\"],false,[\"eq\",\"concat\",\"on\",\"fn\"]]",
    "moduleName": "onboarding/components/animated-radio.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});