define("onboarding/validations/login", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    phoneNumber: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }), (0, _validators.validateLength)({
      min: 12
    }), (0, _validators.validateFormat)({
      regex: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
      message: '{description} is not valid (Example: +440204444444)'
    })],
    confirmationCode: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }), (0, _validators.validateLength)({
      min: 6
    }), (0, _validators.validateNumber)({
      integer: true
    })]
  };
  _exports.default = _default;
});