define("onboarding/routes/meeting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MeetingRoute extends Ember.Route {}

  _exports.default = MeetingRoute;
});