define("onboarding/controllers/project/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProjectDocumentsController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ProjectDocumentsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "firebaseApp", _descriptor, this);

      _defineProperty(this, "acceptedTypes", 'application/msword, application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,application/pdf');

      _initializerDefineProperty(this, "dragover", _descriptor2, this);

      _initializerDefineProperty(this, "inProgress", _descriptor3, this);

      _initializerDefineProperty(this, "uploaded", _descriptor4, this);
    }

    async uploadDocument(file) {
      try {
        const auth = await this.firebaseApp.auth();

        if (auth.currentUser) {
          const storage = await this.firebaseApp.storage();
          const storageRef = storage.ref();
          const documentRef = storageRef.child(`${auth.currentUser.phoneNumber.substring(1)}-${file.name}`);
          file.set('state', 'uploading');
          const fileArrayBuffer = await file.readAsArrayBuffer();
          const response = await documentRef.put(fileArrayBuffer);

          if (response.state === 'success') {
            file.set('state', 'uploaded');
          } else {
            file.set('state', 'failed');
          }

          this.inProgress = false;
          file.queue.flush();
          this.uploaded.push(file);
        } else {
          alert("unauthenticated");
          this.transitionToRoute('project');
        }
      } catch (error) {
        alert(error);
        file.set('state', 'failed');
        file.queue.flush();
        this.inProgress = false;
      }
    }

    isDragging(isDragging) {
      this.dragover = isDragging;
    }

    fileDropped() {
      this.dragover = false;
    }

    fileAdd() {
      this.inProgress = true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "firebaseApp", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dragover", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inProgress", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uploaded", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "uploadDocument", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "uploadDocument"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDragging", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isDragging"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileDropped", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "fileDropped"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileAdd", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "fileAdd"), _class.prototype)), _class));
  _exports.default = ProjectDocumentsController;
});