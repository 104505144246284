define("onboarding/components/animated-input-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="animated-wrapper {{if (get @changeset.error @field) "invalid"}}" >
      <span class="input {{if (get @changeset @field) 'input--filled'}}">
          <FaIcon @icon={{@icon}} />
          {{yield}}
          <label class="input__label" for="input-1">
              <span class="input__label-content">{{@label}}</span>
          </label>
      </span>
      {{#if (get @changeset.error @field)}}
          <div class="validations">
          {{#each (get (get @changeset.error @field) "validation") as |validation| }}
              <p class="validation-message">{{validation}}</p>
          {{/each}}
          </div>
      {{/if}}
  </div>
  
  */
  {
    "id": "R4DVxtAK",
    "block": "[[[10,0],[15,0,[29,[\"animated-wrapper \",[52,[28,[37,1],[[30,1,[\"error\"]],[30,2]],null],\"invalid\"]]]],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[\"input \",[52,[28,[37,1],[[30,1],[30,2]],null],\"input--filled\"]]]],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@icon\"],[[30,3]]],null],[1,\"\\n        \"],[18,6,null],[1,\"\\n        \"],[10,\"label\"],[14,0,\"input__label\"],[14,\"for\",\"input-1\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"input__label-content\"],[12],[1,[30,4]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,1,[\"error\"]],[30,2]],null],[[[1,\"        \"],[10,0],[14,0,\"validations\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[28,[37,1],[[28,[37,1],[[30,1,[\"error\"]],[30,2]],null],\"validation\"],null]],null]],null],null,[[[1,\"            \"],[10,2],[14,0,\"validation-message\"],[12],[1,[30,5]],[13],[1,\"\\n\"]],[5]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@changeset\",\"@field\",\"@icon\",\"@label\",\"validation\",\"&default\"],false,[\"if\",\"get\",\"fa-icon\",\"yield\",\"each\",\"-track-array\"]]",
    "moduleName": "onboarding/components/animated-input-wrapper.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});