define("onboarding/adapters/application", ["exports", "emberfire/adapters/realtime-database"], function (_exports, _realtimeDatabase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _realtimeDatabase.default.extend({});

  _exports.default = _default;
});