define("onboarding/templates/project/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UuOPhQnM",
    "block": "[[[11,\"form\"],[4,[38,0],[\"submit\",[28,[37,1],[[30,0,[\"save\"]],[30,1]],null]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"question\"],[12],[1,\"How could you sum up your project in few sentences?\"],[13],[1,\"\\n    \"],[8,[39,2],[[24,\"}}\",\"\"]],[[\"@icon\",\"@changeset\",\"@field\"],[\"scroll\",[30,1],\"summary\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,1,\"email\"],[24,\"size\",\"500\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"summary\"]]]],null],[1,\"    \"]],[]]]]],[1,\"\\n    \\n    \"],[8,[39,4],[[24,0,\"button button-navigation inline narrow\"]],[[\"@route\"],[\"project.team\"]],[[\"default\"],[[[[10,1],[12],[8,[39,5],null,[[\"@icon\"],[\"chevron-circle-left\"]],null],[13]],[]]]]],[1,\"\\n    \"],[11,\"button\"],[24,0,\"button button-navigation inline\"],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"save\"]],[30,1]],null]],null],[12],[1,\"\\n        \"],[10,1],[12],[8,[39,5],null,[[\"@icon\"],[\"cloud-upload-alt\"]],null],[1,\" CONTINUE \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"@model\"],false,[\"on\",\"fn\",\"animated-input-wrapper\",\"textarea\",\"link-to\",\"fa-icon\"]]",
    "moduleName": "onboarding/templates/project/summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});