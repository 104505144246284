define("onboarding/templates/meeting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DiHl5918",
    "block": "[[[10,0],[14,0,\"calendly-inline-widget\"],[14,\"data-url\",\"https://calendly.com/refineri/30min?background_color=222323&text_color=cedd37&primary_color=f15a2b\"],[14,5,\"min-width:320px;height:630px;\"],[12],[13],[1,\"\\n\"],[10,\"script\"],[14,\"src\",\"https://assets.calendly.com/assets/external/widget.js\"],[14,4,\"text/javascript\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "onboarding/templates/meeting.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});