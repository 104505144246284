define("onboarding/templates/requested", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NlZTeEFx",
    "block": "[[[10,\"form\"],[14,\"autocomplete\",\"off\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,null],[1,\"\\n    \"],[10,0],[14,0,\"subheader\"],[12],[1,\"THANK YOU FOR REQUESTING A NON-DISCLOSURE AGREEMENT\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"text\"],[12],[1,\"YOU SHOULD RECEIVE THE DOCUMENT SHORTLY\"],[13],[1,\"\\n\"],[13]],[],false,[\"fire\"]]",
    "moduleName": "onboarding/templates/requested.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});