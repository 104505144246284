define("onboarding/templates/project/type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vEMBh5BE",
    "block": "[[[10,\"form\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"question\"],[12],[1,\"What's the nature of help that you need?\"],[13],[1,\"\\n    \"],[8,[39,0],null,[[\"@model\",\"@field\"],[[30,1],\"helpType\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"Bussiness & Planning\",\"business\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,1],null,[[\"@label\",\"@value\",\"@selected\",\"@name\",\"@onupdate\"],[\"Design & Development\",\"development\",[30,4],[30,2],[30,3]]],null],[1,\"\\n        \"],[8,[39,1],null,[[\"@label\",\"@value\",\"@name\",\"@selected\",\"@onupdate\"],[\"Both\",\"both\",[30,2],[30,4],[30,3]]],null],[1,\"\\n    \"]],[2,3,4]]]]],[1,\"\\n    \"],[8,[39,2],[[24,0,\"button button-navigation\"]],[[\"@route\"],[\"project.stage\"]],[[\"default\"],[[[[10,1],[12],[8,[39,3],null,[[\"@icon\"],[\"chevron-circle-right\"]],null],[1,\" CONTINUE\"],[13]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\",\"field\",\"update\",\"selected\"],false,[\"animated-radio-container\",\"animated-radio\",\"link-to\",\"fa-icon\"]]",
    "moduleName": "onboarding/templates/project/type.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});